.Modal {
    position: fixed;
    bottom: 0;
    z-index: 98;
    background: #012C7F;
    color: #fff;
    width: 100%;
    height: fit-content;
    padding: 16px;
    box-sizing: border-box;
    transition: all 0.25s ease-out;
}
