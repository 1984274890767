.OurTeam {
    position: relative;
    top: 100px;
    height: fit-content;
    width: 100%;
    padding: 64px 156px;
    background: #EDF2F4;
}
.OurTeam h3 {
    font-weight: 600;
    font-size: 36px;
    color: #000;
    text-align: center;
}
.Wrapper {
    width: fit-content;
    height: fit-content;
    margin-top: 64px;
}

@media(max-width: 1100px) {
    .OurTeam {
        padding: 64px 44px;
    }
}
@media(max-width: 1000px) {
    .OurTeam {
        padding: 64px 16px;
    }
}
