.MobileBanking {
    position: relative;
    top: 100px;
    padding: 96px 156px;
    height: fit-content;
    background: #fff;
}
#googlePlayBadge {
    height: fit-content;
}
.MobileBanking h5 {
    color: #758C9D;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    margin-bottom: 87px;
}
.Wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: left !important;
}
.Wrapper h2 {
    font-weight: 500;
    font-size: 39px;
    line-height: 46.8px;
    margin-bottom: 16px;
}
.Wrapper h2 span {
    color: #012C7F;
    font-weight: 600;
}
.Wrapper p {
    font-size: 18px;
    color: #525252;
    line-height: 36px;
    margin-bottom: 44px;
}
.Wrapper p span {
    font-weight: 600;
}
.ButtonWrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ButtonWrap span {
    width: 47%;
}
.ComingSoon {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 50px;
}
@media(max-width: 1100px) {
    .MobileBanking {
        padding: 107px 44px;
    }
}
@media(max-width: 1030px) {
    .MobileBanking {
        padding: 107px 44px;
        text-align: center;
    }
    .Wrapper {
        margin: 0 auto;
        text-align: center !important;
    }
}
@media(max-width: 768px) {
    .MobileBanking {
        padding: 64px 17px;
        text-align: center;
    }
    .Wrapper {
        margin: 0 auto;
        width: 70%; 
    }
}
@media(max-width: 500px) {
    .MobileBanking {
        padding: 64px 17px;
        text-align: center;
    }
    .Wrapper {
        margin: 0 auto;
        width: 100%;
    }
}