.Input, .Select {
  width: 100%;
  padding: 14px 16px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border-style: none;
  font-size: 15px;
  outline: none;
  margin-top: 8px;
}
.Select {
  padding: 4px 3px;
  outline: none;
  font-family: 'IBM Plex Sans';
}
.HelperText {
  color: #3c4152;
  font-size: 14px;
}

.LoanInput , .FileInput{
  border: none;
  background: rgba(0, 0, 0, 0.071);
  box-shadow: inset 4px 0px 0px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 12px 14px;
  outline: none;
  height: 40px;
  width: 100%;
  font-size: 1rem;
}
.FileInput {
  height: fit-content;
}
.InputWrap {
  flex: 1;
  flex-direction: column;
  margin-bottom: 36px;
}
.Wrap {
  flex: 1;
  flex-direction: column;
}
.LoanSelect,
.DateInput {
  border: none !important;
  background: rgba(0, 0, 0, 0.071);
  box-shadow: inset 4px 0px 0px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  outline: none !important;
  flex: 1;
  height: 100%;
}
.DateInput {
  margin-bottom: 36px !important;
}
.LoanInput::placeholder,
#SelectLabel,
.LoanSelect::placeholder,
.DateInput::placeholder, .Select::placeholder {
  color: #6d7592;
  font-size: 1rem;
  font-family: "IBM Plex Sans";
}
#SelectLabel {
  color: #3c4152;
}

