.ServiceCard {
    height: fit-content;
    position: relative;
    background: #D9E2F2;
    padding: 32px 24px;
    text-align: center;
    top: 79px;
}
.Details {
    height: fit-content;
    position: relative;
    padding: 32px 24px;
    top: 79px;
}
.Popular {
    position: relative;
    top: 0px;
}

.ServiceCard h2 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
}
.ServiceCard p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 23px;
}
.ServiceCard div {
    font-size: 48px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 63px;
}
.Details p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 24px;
}
.Details h4 {
    font-size: 16px;
    font-weight: 300;
    text-decoration: underline;
    margin-bottom: 16px;
}
.Details ul li {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    list-style:disc;
    padding-bottom: 8px;
}

@media(max-width: 1000px) {
    .Details {
        display: none;
    }
    .ServiceCard {
        height: 390px;
        text-align: center;
        top: 0;
    }
    .Popular h2 {
        font-size: 20px;
    }
    .Popular p {
        font-size: 12px;
        line-height: 18px;
    }
    .Popular div {
        font-size: 42px;
        margin-bottom: 43px;
    }
}