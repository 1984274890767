.Location {
    position: relative;
    top: 100px;
    height: fit-content;
    width: 100%;
    padding: 64px 156px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.map {
    border: none;
    width: 65%;
    height: 521px;
    padding: 24px;
}
.Content h2{
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 16px;
}
.Content p {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 16px;
}
.Content a {
    color: #012C7F;
    font-size: 24px;
    font-weight: 500;
}
.ButtonWrap {
    margin-top: 60px;
    width: 200px;
}
@media(max-width: 1100px) {
    .Location {
        padding: 64px 46px;
    }
}
@media(max-width: 1000px) {
    .Location {
        padding: 64px 16px;
        flex-direction: column;
    }
    .map {
        width: 100%;
        padding: 0;
    }
    .Content {
        width: 100%;
        padding: 0;
        text-align: center;
        margin-top: 22px;
    }
    .ButtonWrap {
        width: 100%;
    }
}
@media(max-width: 768px) {
    .Content h2 {
        font-size: 42px;
    }
    .Content p {
        font-size: 20px;
    }
    .Content a {
        font-size: 20px;
    }
}