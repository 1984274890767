.FrAQ {
    position: relative;
    top: 100px;
    height: fit-content;
    width: 100%;
    padding: 96px 156px;
    background: #fff;
}
.Container {
    height: 1312px;
    overflow-y: auto;
}
.FrAQ h1 {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 42px;
}
.Question {
    background: #EFF2F5;
    padding: 50px 32px;
    height: 303px;
    width: 100%;
    overflow-y: auto;
}
.Question h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 8px;
}
.Question p {
    font-size: 14px;
    font-weight: 400;
    line-height: 25.2px;
}

@media(max-width: 1100px) {
    .FrAQ {
        padding: 96px 44px;
    }
}
@media(max-width: 1000px) {
    .FrAQ {
        padding: 96px 16px;
    }
}
@media(max-width: 500px) {
    .FrAQ {
        padding: 65px 16px;
    }
    .FrAQ h1 {
        font-size: 28px;
    }
}
