.OurServices {
    height: fit-content;
    width: 100%;
    background: #fff;
    position: relative;
    top: 100px;
    padding: 96px 156px;
}
.OurServices h5 {
    color: #758C9D;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    margin-bottom: 60px;
}
.Wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.TextCard {
    width: 280px;
    height: fit-content;
}
.TextCard2 {
    height: fit-content;
    text-align: right;
    margin: 20px 0px;
}
.TextCard h3 {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 24px;
}
.TextCard p {
    font-size: 18px;
    line-height: 28px;
    color: #525252;
    margin-bottom: 36px;
}
.TextCard2 h3 {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 24px;
}
.TextCard2 p {
    font-size: 18px;
    line-height: 28px;
    color: #525252;
    margin-bottom: 36px;
}
.textbuttonwrap {
    height: fit-content;
    width: fit-content;
}

@media(max-width: 768px) {
    .OurServices {
        padding: 65px 14px;
    }
    .OurServices h5 {
        text-align: center;
    }
    .Wrapper {
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .TextCard {
        margin: 0 auto;
        text-align: center;
        width: 100%;
    }
    .textbuttonwrap{
        margin: 0 auto;
    }
    .TextCard2 {display: none;}
}
