.FormProgress {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 60px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}
.FormProgress:focus {
    background: none;
    outline: none;
}
.ProgressBar {
    width: 20%;
    height: 6px;
    background: #C4C4C4;
    margin-right: 24px;
}
.ProgressBar:last-child {
    margin-right: 0;
}
