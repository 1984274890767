.MobileMenu {
    height: 100%;
    width: 100%;
    position: fixed;
    background: #012C7F;
    z-index: 100;
    display: flex;
    flex-direction: column;
    padding: 33.4px 65px;
    transition: 0.3s ease-in-out;
}
.MobileMenu ul {
    text-align: right;
    color: #fff;
    list-style: none;
    font-size: 48px;
    font-weight: 700;
    color: #A8DADC;
    width: fit-content;
    float: right;
}
.MobileMenu ul li {
    margin-bottom: 64px;
}
.Wrapper {
    display: flex;
    flex-direction: row-reverse;
}
.MobileMenu span {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 67.56px;
}

@media(max-width: 500px) {
    .MobileMenu {
        padding: 87px 39px;
    }
}