.BreadCrumb {
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(22, 22, 22, 0.1), inset 0px 5px 0px #0f62fe;
  padding: 20px;
  margin: 10px 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.BreadCrumb .message {
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.BreadCrumb p span {
    font-weight: 500;
}
.BreadCrumb p img {
    margin-left:5px;
}
