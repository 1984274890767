.ServiceCard {
  height: 380px;
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(22, 22, 22, 0.1);
  padding: 62px 32px;
  position: relative;
}
.ButtonWrap {
    width: 161px;
    margin-top: 24px;
}
.ServiceCard h1 {
    font-size: 32px;
    font-weight: 500;
}
.ServiceCard p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}
.ServiceCard img {
    margin: 24px 0px;
}
.Popular {
    position: absolute;
    padding: 6px 30px;
    background: #FFE6E6;
    color: #7D0202;
    border-radius: 4px;
    top: 21px;
    right: 15px;
}