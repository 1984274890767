.LoanSideBar {
    width: 400px;
    height: 191%;
    background: url('../../../../assets//images/formimage.webp');
    background-size: cover;
    z-index: 100;
}

@media(max-width: 768px) {
    .LoanSideBar {
        display: none;
    }
}