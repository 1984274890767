.TopSection {
    position: relative;
    top: 100px;
    height: 327px;
    width: 100%;
    padding: 134.5px 156px;
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../../../../assets/images/loanimage.webp');
    background-size: cover;
    background-position-y: -400px;
    background-position-x: -100px;
}
.TopSection h1 {
    font-size: 48px;
    font-weight: 700;
    color: #fff;
}

@media(max-width: 1100px) {
    .TopSection {
        padding: 134.5px 41.5px;
        background-position-x: 0;
        background-position-y: -100px;
    }
}

@media(max-width: 768px) {
    .TopSection {
       height: 607px;
       padding: 0;
       background-position-y: 0;
    }
    .TopSection h1 {
        text-align: center;
        line-height: 607px
    }
}
@media(max-width: 500px) {
    .TopSection {
       height: 534px;
       padding: 0;
       background-position-x: -300px;
    }
    .TopSection h1 {
        text-align: center;
        line-height: 534px;
    }
}

