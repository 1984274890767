.Gallery {
    height: fit-content;
    width: 100%;
    padding: 96px 150px;
    display: flex;
}
.Image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media(max-width: 1100px) {
    .Gallery {
        padding: 64px 44px;
    }
}
@media(max-width: 1000px) {
    .Gallery {
        padding: 64px 16px;
    }
}
