.TopSection {
    height: fit-content;
    position: relative;
    top: 100px;
    width: 100%;
    background: #fff;
}
.MainText{
    width: 492px;
    height: fit-content;
    color: #CCDFFF;
    font-size: 48px;
    line-height: 57.6px;
    z-index: 5;
    position: relative;
    margin-top: 200px;
}
.TopWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.top {
    height: 838px;
    width: 100%;
    background: #012C7F;
    position: absolute;
    top: 0;
    z-index: 1;
}

.Wrapper {
    margin-top: 0px;
    height: fit-content;
    width: fit-content;
    position: relative;
    top: -46px;
    padding: 0px 156px;
    z-index: 5;
}

@media(max-width: 1100px) {
    .TopSection h1 {
        margin-left: 44px;
        margin-top: 64px;
    }
    .Wrapper {
        padding: 0px 44px;
    }
}
@media(max-width: 1000px) {
    .TopSection h1 {
        margin-top: 96px;
        text-align: center;
    }
    .Wrapper {
        padding: 0px 16px;
    }
}
@media(max-width: 1000px) {
    .TopWrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .top {
        display: none;
    }
    .TopSection {
        height: fit-content;
        background: #012C7F;
    }
    .Wrapper {
        top: 0px;
        margin-bottom: 30px;
    }
    .TopSection h1 {
        
        text-align: center;
        margin: 0 auto;
        margin-top: 64px;
        width: fit-content;
    }
}

@media(max-width: 768px) {
    .TopSection h1 {
        font-size: 42px;
        line-height: 42px;
    }
    .Wrapper {
        padding: 0px 30px;
    }

}