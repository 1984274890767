.OurBlog {
    padding: 90px 156px;
    width: 100%;
    position: relative;
    top: 100px;
    background: #EDF2F4;
}
.OurBlog h5{
    color: #758C9D;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    margin-bottom: 60px;
    display: flex;
    flex-direction: row-reverse;
}
.Media {
    height: 430px;
    width: 60%;
    background-repeat: no-repeat;
    background: url('../../../../assets/images/ourblogimage.webp');
    background-size: cover;
    margin-right: 24px;
}
.Content {
    width: 40%;
    text-align: right;
}
.Content h3 {
    font-size: 39px;
    margin-bottom: 16px;
    font-weight: 700;
}
.Content p {
    font-size: 18px;
    color: #525252;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 45px;
}
.ButtonWrap {
    width: 264px;
    float: right;
}
.Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media(max-width: 1000px) {
    .OurBlog {
        padding: 64px 16px;
    }
    .OurBlog h5 {
        display: block;
        text-align: center;
    }
    .Wrapper {
        flex-direction: column;
    }
    .Media {
    width: 100%;
    margin-bottom: 30px;
    }
    .Content {
        width: 100%;
        text-align: center;
    }
    .ButtonWrap {
        width: 100%;
    }
}