.HeroForm {
  height: fit-content;
  width: 410px;
  padding: 30px;
  border-radius: 4px;
  background: #e6eeff;
  position: relative;
  /* Drop shadow light */

  box-shadow: 0px 10px 20px rgba(22, 53, 55, 0.15);
  z-index: 10;
}

.FormHeader {
    height: fit-content;
    width: 100%;
    margin: 30px 0px;
    text-align: center;
}
.FormHeader h1 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 6px;
}
.FormHeader p {
    font-size: 14px;
    font-weight: 300;
}
#Wrap {
    margin-bottom: 14px;
}
#Wrap:nth-child(4) {
    padding-bottom: 25px;
}
.ViewRates {
    margin-top: 35px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
@media(max-width: 900px) {
    .HeroForm {
        width: 400px;
        height: fit-content;
    }
    .FormHeader h1 {
        font-size: 30px;
    }
}
@media(max-width: 768px) {
    .HeroForm {
        width: 90%;
        height: fit-content;
    }
}