.LoanPolicy {
    position: relative;
    top: 100px;
    padding: 40px 156px;
}
.LoanPolicy p span {
    font-weight: 700;
}
.LoanPolicy h1 {
    text-align: center;
}
.LoanPolicy h2 {
    padding: 25px 0px;
}
.LoanPolicy p {
    line-height: 30px;
    font-size: 18px;
}
@media(max-width: 1000px) {
    .LoanPolicy {
        padding: 40px 96px;
    }
}
@media(max-width: 768px) {
    .LoanPolicy {
        padding: 40px 16px;
    }
}