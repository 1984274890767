.Card {
    height: 375px;
    background: #fff;
    box-shadow: 0px 20px 40px rgba(22, 22, 22, 0.1), inset 0px 12px 0px #0F62FE;
    padding: 62px 32px;
}
.CardContent h3{
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 24px;
}

.CardContent p {
    font-size: 14px;
    color: #000;
    line-height: 20px;
    margin: 24px 0px;
}
.Card:nth-child(4) {
    margin-top: 60px;
}

@media(max-width: 768px) {
    .Card {
        margin: 0 auto;
        margin-bottom: 22px;
    }
    .Card:nth-child(2){
        margin-top: 30px;
    }
    .Card:nth-child(4){
        margin-top: 0;
    }
}