.FormSection {
    padding: 65px 100px;
    height: fit-content;
    position: relative;
    width: 100%;
}

.FormDetails {
    margin-top: 36.75px;
}
.FormDetails h2 {
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 23.47px;
    transition: 0.5s ease-in-out;
}
.FormDetails p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    transition: 0.5s ease-in-out;
}
.FormWrap {
    position: relative;

    height: fit-content;
}
.FormGroup {
    display: flex;
    flex-direction: column;
    margin-top: 48.09px;
    height: fit-content;
    width: 100%;
    transition: 0.3s ease-in;
    position: absolute;
    padding-bottom: 65px;
}
.FormGroup input { 
  
  flex: 1;
}
.SelectWrap {
    flex: 1;
}
.SelectWrap:first-child {
    margin-right: 3%;
}
.InputWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.InputWrap div:first-child {
    margin-right: 3%;
}


@media(max-width: 1000px) {
    .FormSection {
        padding: 30px;
    }
}

@media(max-width: 768px) {
    .FormSection {
        padding: 30px;
    }
    
    .InputWrap div:first-child {
        margin-right: 0;
    }
    .InputWrap {
        flex-direction: column;
    }
}