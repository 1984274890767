.HeroSection {
  height: fit-content;
  width: 100%;
  background: linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.1)), url("../../../../assets/images/simone-hutsch-VIu1Us2qWq4-unsplash.webp");
  background-size: cover;
  background-position-y: -500px;
  align-items: center;
  position: relative;
  display: flex;  
  top: 100px;
  padding: 36.7px 0px;
}
.Headline {
  width: 50%;
  height: fit-content;
  position: relative;
}
.Headline h1 {
  font-weight: 700;
  font-size: 45px;
}
.Headline ul {
    margin-top: 30px;
    position: relative;
    left: 21px;
    
}
.Headline ul li {
    color: #181818;
    font-size: 20px;
    padding-bottom: 5px;
    font-weight: 600;
}
.ButtonWrap {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
}
.ButtonWrap span {
    width: 264px;
    margin-right: 24px;
}
.Container {
    height: fit-content;
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 156px;
}

@media(max-width: 1100px) {
  .Container {
    width: 93%;
    margin: 0 auto;
  }
}
@media(max-width: 1000px) {
  .Container {
    width: 95%;
  }
}
@media(max-width: 768px) {
  .HeroSection {
    height: 800px;
    background-position-y: 0;
    top: 100px;
  }
  .Container {
    flex-direction: column;
  }
  .Headline {
    width: 90%;
    margin-bottom: 70px;
    text-align: center;
    margin-top: 500px;
  }
  .Headline h1 {
    font-size: 42px;
    line-height: 46px;
  }
  .Headline ul {
    left: 0;
    margin-top: 24px;
  }
  .Headline ul li {
    list-style: none;
  }
  .ButtonWrap {
    flex-direction: column;
  }
  .ButtonWrap span{
    width: 100%;
  }
}

@media(max-width: 500px) {
  .HeroSection {
  background-position-x: -100px;

  }
}