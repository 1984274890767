.TopSection {
    padding: 84px 156px;
    background: #012C7F;
    color: #fff;
    text-align: center;
    position: relative;
    top: 100px;
}
.TopSection h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    letter-spacing: 0.15em;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 27px;
}
.TopSection h1 {
    font-weight: 700;
    font-size: 70px;
    line-height: 86.4px;
    margin-bottom: 30px;
}
.TopSection h1 span {
    opacity: 0.7;
}
.TopSection p {
    font-size: 24px;
    font-weight: 500;
    line-height: 33.6px;
    margin: 0 auto;
    width: 70%;
}

@media(max-width: 1100px) {
    .TopSection {
        padding: 79px 110px;
    }
}
@media(max-width: 1000px) {
    .TopSection {
        padding: 250px 120px;
    }
    .TopSection h1 {
        font-size: 50px;
        line-height: 60px;
    }
    .TopSection p {
        width: 100%;
    }
}
@media(max-width: 768px) {
    .TopSection {
        padding: 78px 16px;
    }
    .TopSection h1 {
        font-size: 42px;
        line-height: 50px;
    }
    .TopSection p {
        font-size: 18px;
    }
}
