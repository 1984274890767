.Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px 80px;
    background: #fff;
    width: 100%;
    position: fixed;
    z-index: 50;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.Header ul {
    display: flex;
    list-style: none;
    align-items: center;
}
.Header ul li {
    margin-right: 32px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
}
#burgerIcon {
    display: none;
}

@media (max-width: 1000px) {
    .Header {
        padding: 37px 17px;
    }
    .Header ul {
        display: none;
    }
    #burgerIcon {
        display: flex;
        cursor: pointer;
    }
}