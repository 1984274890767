.OurMission {
    position: relative;
    top: 100px;
    background: #EDF2F4;
    text-align: center;
    height: fit-content;
    width: 100%;
}

.Wrapper {
    height: fit-content;
    width: 100%;
    padding: 70px 200px;
}

.Wrapper h5 {
    color: #758C9D;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
}
.Wrapper p:first-of-type {
    font-size: 32px;
    margin: 10px 0;
    line-height: 35.2px;
}
.Wrapper p span {
    color: #012C7F;
    font-weight: 600;
}
.Wrapper p:last-of-type {
    color: #595959;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
}
@media(max-width: 1000px) {
    .Wrapper {
        padding: 70px 40px;
    }
}
@media(max-width: 768px) {
    .OurMission {
        height: 750px;
    }
    .Wrapper {
        position: relative;
        top: 400px;
    }
    .Wrapper p:first-of-type {
        margin: 24px 0;
    }
}