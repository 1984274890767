.OurStory {
  height: fit-content;
  width: 100%;
  padding: 96px 156px;
  position: relative;
  top: 100px;
}
.OurStory h3.Title {
  color: #000;
  font-weight: 600;
  font-size: 36px;
  width: 100%;
  margin-bottom: 32px;
}
.Content {
    padding-right: 25px;
    line-height: 48px;
    color: #666666;
    font-size: 24px;
}
.Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.StatsWrap {
  display: flex;
  flex-direction: column;
}
.Stat {
  height: fit-content;
  width: 360px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(22, 53, 55, 0.15);
  padding: 16px 100px;
  margin-bottom: 24px;
}
.Stat p {
  font-size: 20px;
}
.Stat h3 {
  font-size: 40px;
  margin: 25px auto;
}

@media(max-width: 1030px) {
    .OurStory {
        padding: 96px 16px;
    }
    .Wrapper {
        flex-direction: column;
    }
    .StatsWrap {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-top: 32px;
    }
    .Stat {
        width: 48%;
        margin-bottom: 26px;
        height: 200px;
    }
}
@media(max-width: 768px) {
    .OurStory {
        padding: 64px 16px;
    }
    .StatsWrap {
        flex-direction: column;
    }
    .Stat {
        width: 100%;
    }
}