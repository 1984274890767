.NotFound {
    position: relative;
    top: 100px;
    height: 100%;
    width: 100%;
} 
.Wrap {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}
.NotFound div p {
    font-size: 60px;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
}
.RouteLinks {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-between;
    width: fit-content;
    text-align: center;
    margin-top: 20px;
}
.RouteLinks span {
    cursor: pointer;
    font-weight: 500;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
}
.RouteLinks span:first-child {
    border-right: 1px solid black;
}
.RouteLinks span img {
    transform: rotate(180deg);
    margin-right: 10px;
}
@media(max-width: 768px) {
    .Wrap {
        width: 100%;
    }
}