.OtherLoans {
    position: relative;
    top: 100px;
    padding: 82px 156px;
}
.Title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 56px;
}
@media(max-width: 1100px) {
    .OtherLoans {
        padding: 80px 44px;
    }
}
@media(max-width: 768px) {
    .OtherLoans {display: none;}
}