.LoanDescription {
    position: relative;
    top: 100px;
}
.MainContent {
    width: 746px;
    padding-top: 106px;
    padding-bottom: 200px ;
    margin-right: 21.97px;
}
.MainContent p {
    margin-bottom: 52px;
    font-size: 18px;
    line-height: 36px;
    color: #39393A;
}
.ButtonWrap {
    width: 188px;
}
.Features {
    display: flex;
    flex-direction: column;
    padding: 86px 26px;
    background: #ECF0F9;
    width: 360px;
    height: fit-content;
}
.Features div:first-child {
    margin-bottom: 48px;
}
.Features div h3 {
    font-size: 24px;
    font-weight: 500;
    color: #39393A;
    text-align: center;
    margin-bottom: 16px;
}
.Features div ul {
    color: #39393A;
    font-size: 16px;
}
.Features div ul li {
    line-height: 32px;
}
.Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 156px;
    width: 100%;
    height: fit-content;
}

@media(max-width: 1100px) {
    .Wrapper {
        padding: 0px 44px;
    }
}
@media(max-width: 768px) {
    .Wrapper {
        padding: 64px 0px;
        flex-direction: column;
    }
    .MainContent {
        padding: 0px 16px;
        padding-bottom: 64px;
    }
    .MainContent p {
        margin-bottom: 0;
    }
    .MainContent, .Features {
        width: 100%;
        height: fit-content;
    }
    .Features div:last-child {
        margin-top: 64px;
    }
    .ButtonWrap {
        margin-top: 30px;
    }
}
@media(max-width: 500px) {
    .Wrapper {
        padding: 41px 16px;
    }
    .MainContent {
        padding: 0;
        padding-bottom: 64px;
    }
}