.Button {
    padding: 24px 43px;
    background: #161616;
    border-radius: 4px;
    border-style: none;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    width: 100%;
    height: fit-content;
    cursor: pointer;
}

.Button:disabled {
    background: #484848;
}

.TextButton {
    background: none;
    text-decoration-line: underline;
    font-size: 16px;
    font-weight: 600;
    border: none;
    line-height: 22px;
    padding: 2px;
    color: #000;
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.TextButton img {
    margin-left: 16px;
}