.TeamCard {
  height: 630px;
  padding: 24px;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(22, 53, 55, 0.15);
  border-radius: 4px;
  text-align: center;
}
.TeamCard img {
    height: 312px;
    width: 100%;
    object-fit: cover;
}
.TeamCard h2 {
    margin: 4px 0;
    font-weight: 600;
    font-size: 26px;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 5px 0;    
}
.Position {
    margin-bottom: 20px;
    font-size: 20px;
}
.TeamCard p:last-of-type {
    line-height: 27px;
    font-size: 16px;
    text-align: center;
    height: 150px;
    overflow-y: auto;
}

@media(max-width: 768px) {
    .TeamCard {
        margin: 0 auto;
    }
}