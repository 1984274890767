.LoanProcess {
    margin-top: 20px;
    width: 100%;
    height: fit-content;
}
.LoanProcess p:first-child{
    text-align: center;
    font-size: 18px;
    font-weight: 400;
}
.ProgressBar {
    height: 5px;
    width: 100%;
    background: #fff;
    padding: 0px 70px;
    margin-top: 75px;
    display: flex;
    flex-direction: row;
}
.arrayItem {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 10px;
    background: #fd353500;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}
.arrayItem::before {
    content: attr(data-item);
    text-align: center;
    text-transform: capitalize;
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: 400;
    position: relative;
    top: -30px;
}
.activeStyle {
    background: #fd3535;
}
.StepInfo {
    width: 50%;
    height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
    text-align: left;
}
.StepInfo h3 {
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 10px;
    transition: 0.2s ease-in-out;
}
.StepInfo p {
    line-height: 36px;
    letter-spacing: 0.5px;
    font-weight: 400;
    font-size: 18px;
    transition: 0.2s ease-in-out;
}
.StepInfo p span {
    font-weight: 600;
}
@media(max-width: 1000px) {
    .ProgressBar {
        background: #CCDEFF;
    }
    .activeStyle {
        background: #012C7F;
    }
}
@media(max-width: 600px) {
    .ProgressBar {
        padding: 0px 16px;
    }
    .StepInfo {
        width: 80%;
    }
}
