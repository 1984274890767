.Testimonials {
    position: relative;
    top: 100px;
    padding: 96px 156px;
    background: #EDF2F4;
    text-align: center;
}
.Testimonials h5 {
    color: #758C9D;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    margin-bottom: 12px;
}
.Testimonials h2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 50px;
    margin-bottom: 64px;
}
.Testimonials h2 span {
    color:#012C7F;
    font-weight: 600;
}
.TestimonialWrap {
    width: 100%;
    height: fit-content;
}
.TestimonialCard {
    height: 350px;
    background: #fff;
    padding: 62px 32px;
    overflow-y: auto;
}
.TestimonialCard h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 50px;
    margin-bottom: 24px;
}
.TestimonialCard p {
    font-size: 14px;
    line-height: 28px;
}
@media(max-width: 1030px) {
    .Testimonials {
        padding: 34px 16px;
        text-align: center;
    }
    .Wrapper {
        margin: 0 auto;
    }
}
@media(max-width: 500px) {
    .Testimonials {
        padding: 84px 17px;
        text-align: center;
    }
    .TestimonialCard {
        height: fit-content;
    }
}