.Footer {
    position: relative;
    top: 100px;
    padding-top: 79px;
    background: #012C7F;
    height: fit-content;
    width: 100%;
    color: #fff;
}
.Wrapper {
    padding: 0px 156px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.auditImage {
    height: 70px;
    width: 210px;
    object-fit: contain;
}
.LogoWrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 30px 0px;
}
.SocialLinks li {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.SocialLinks li img {
    height: 20px;
    width: 20px;
    margin-left: 10px;
}
.MainList {
    width: 30%;
    margin-right: 150px;
}
.Wrapper ul {
    list-style: none;
}
.Wrapper ul li {
    padding-bottom: 16px;
    cursor: pointer;
}
.Wrapper ul.listWrap li:first-child {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 16px;
}
.FooterBottom {
    margin-top: 50px;
    position: relative;
    clear: both;
    display: flex;
    flex-direction: row-reverse;
    padding: 16px 156px;
    border-top: 1px solid #fff;
}
.FooterBottom ul {
    display: flex;
    flex-direction: row;
}
.FooterBottom ul li {
    padding: 0px 8px;
    cursor: pointer;
}

.FooterBottom ul li:first-child {
    list-style: none;
    position: relative;
    left: -22px;
}
.FooterBottom ul li:nth-child(3) {
    position: relative;
    left: 22px;
}

@media(max-width: 1100px) {
    .FooterBottom {
        padding: 16px 45px;
    }
    .Wrapper {
        padding: 0px 45px;
    }
}
@media(max-width: 1000px) {
    .FooterBottom {
        padding: 16px 32px;
    }
    .Wrapper {
        padding: 0px 32px;
    }
}
@media(max-width: 768px) {
    .Footer {
        padding-top: 36px;
    }
    .FooterBottom {
        padding: 16px 16px;
    }
    .Wrapper {
        padding: 0px 16px;
        flex-direction: column;
    }
    .LogoWrap {
        flex-direction: column;
        margin: 30px auto;
        width: fit-content;
    }
    .auditImage {
        margin-bottom: 20px;
    }
    .auditImage:last-child {
        margin-bottom: 0;
    }
    .Wrapper ul {
        margin-bottom: 36px;
    }
    .Wrapper ul:last-child {
        margin-bottom: 0;
    }
    .MainList {
        margin: 0;
        width: 70%;
    }
    .FooterBottom ul {
        margin: 0 auto;
    }
    .FooterBottom ul li:last-child {
        padding-right: 0;
    }
}