.LoanServices {
    padding: 96px 156px;
    background: #EDF2F4;
    position: relative;
    top: 100px;
}
.Text {
    font-size: 48px;
    font-weight: 700;
}
.Text span {
    color: #7D0203;
}

@media(max-width: 1100px) {
    .LoanServices {
        padding: 111px 44px;
    }
}

@media(max-width: 768px) {
    .LoanServices {
        padding: 55px 16px;
    }
}
@media(max-width: 500px) {
    .Text {
        text-align: center;
    }
}