.LoanApplication {
    height: 560px;
    width: 100%;
    position: relative;
    top: 100px;
    background: #012C7F;
    margin-top: 96px;
    padding: 117px 156px;
    color: #fff;
}

.LoanApplication h2 {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
}
@media(max-width: 1100px) {
    .LoanApplication {
        padding: 117px 45px;
    }
}

@media(max-width: 1000px) {
    .LoanApplication {
        background: #fff;
        color: #000;
        padding: 90px 16px;
        margin-top: 0px;
    }
}
@media(max-width: 500px) {
    .LoanApplication {
        display: none;
    }
}
